<template>
  <div id="postSummaryField">
    <div class="list-item">
      <span>{{ comment }}</span>
      <router-link
        :to="`/post-comments/${id}`"
      >
        <feather-icon
          icon="MessageCircleIcon"
          size="16"
          class="icon"
        />
      </router-link>
    </div>
    <div class="list-item">
      <span>{{ like }}</span>
      <router-link
        :to="`/post-likes/${id}`"
      >
        <feather-icon
          icon="HeartIcon"
          size="16"
          class="icon"
        />
      </router-link>
    </div>
    <div class="list-item">
      <span>{{ share }}</span>
      <router-link
        :to="`/post-shares/${id}`"
      >
        <feather-icon
          icon="Share2Icon"
          size="16"
          class="icon"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    like: {
      type: [Number, String],
      default: 0,
    },
    comment: {
      type: [Number, String],
      default: 0,
    },
    share: {
      type: [Number, String],
      default: 0,
    },
    id: {
      type: [String],
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
#postSummaryField {
    display: flex;
    width: max-content;

    .list-item {
        display: flex;
        line-height: normal;
        align-items: center;
        .icon {
            margin-left: 0.25rem;
        }
        .icon:hover {
            color: #ed5c5e;
        }

        &::after {
            content: "|";
            margin: 0 0.5rem
        }

        &:last-child::after {
            content: ""
        }
    }
}
</style>
