<template>
  <div id="reviewSummary">
    <div>{{ star }}</div>
    <feather-icon
      icon="StarIcon"
      size="16"
      class="icon text-warning"
    />
    <div>/ {{ count }} Reviews</div>
  </div>
</template>

<script>
export default {
  props: {
    star: {
      type: [Number, String],
      default: 0,
    },
    count: {
      type: [Number, String],
      default: 0,
    },
  },
}
</script>

<style lang="scss" scoped>
#reviewSummary {
    display: flex;
    width: max-content;
    align-items: center;

    .icon {
        margin: 0 0.25rem;
    }
}
</style>
