<template>
  <div class="d-flex align-items-center white-space-pre">
    <!-- Prefix -->
    <span v-if="hasValue && defaultPrefix">{{ defaultPrefix }}</span>

    <!-- Id column -->
    <router-link
      v-if="hasValue && defaultType === 'id'"
      v-b-tooltip.hover.ds250.v-dark.top="value"
      :to="detailRoute"
      class="font-monospace"
    >#{{ value.substr(0, 6) }}</router-link>

    <!-- Id column -->
    <router-link
      v-else-if="hasValue && defaultType === 'human-id'"
      v-b-tooltip.hover.ds250.v-dark.top="value"
      :to="detailRoute"
      class="font-monospace"
    >#{{ value.slice(value.length - 6) }}</router-link>

    <!-- Image type's field -->
    <div
      v-else-if="hasValue && defaultType === 'image'"
    >
      <a
        :href="value"
        target="_blank"
      >
        <b-avatar
          variant="light"
          rounded="sm"
          :src="defaultImage"
          size="40px"
        />
      </a>
    </div>

    <!-- Images type's field -->
    <div
      v-else-if="hasValue && defaultType === 'images' && Array.isArray(value) && value.length"
      class="td-long"
    >
      <a
        v-for="(item, index) in value"
        :key="`list-images-${index}`"
        :href="item"
        target="_blank"
        class="mr-50 mb-1"
      >
        <b-avatar
          variant="light"
          rounded="sm"
          :src="item | thumbnail(40)"
          size="40px"
        />
      </a>
    </div>

    <!-- Videos type's field -->
    <div
      v-else-if="hasValue && defaultType === 'videos' && Array.isArray(value) && value.length"
      class="d-flex gap-1"
      style="flex-flow: wrap; max-width: 300px;"
    >
      <a
        v-for="(item, index) in value"
        :key="`list-videos-${index}`"
        :href="item.url"
        target="_blank"
        class="mr-1 mb-1"
      >
        <b-avatar
          variant="light"
          rounded="sm"
          :src="item.image_url | thumbnail(40)"
          size="40px"
        />
      </a>
    </div>

    <!-- Badges type's field -->
    <div
      v-else-if="hasValue && value.length && defaultType === 'badges'"
      class="d-flex gap-1"
    >
      <b-badge
        v-for="(item, index) in defaultBadges"
        :key="`badges-field-${index}`"
        :variant="map[item]"
        class="text-capitalize"
      >
        {{ item }}
      </b-badge>
      <b-badge
        v-if="value.length > 2"
        v-b-tooltip.v-dark.top="{ title: hiddenBadges.join(', '), customClass: 'text-capitalize' }"
        class="text-capitalize"
      >
        + {{ value.length - 1 }} more
      </b-badge>
    </div>

    <!-- Avatars type's field -->
    <div
      v-else-if="hasValue && defaultType === 'avatars' && Array.isArray(value) && value.length"
    >
      <b-avatar-group
        size="32px"
        class="c-avatar-group"
      >
        <b-avatar
          v-for="(item, index) in value"
          :key="`list-avatars-${index}`"
          v-b-tooltip.v-dark.top="{ title: _.get(item, titleKey) }"
          :title="_.get(item, titleKey)"
          size="32px"
          :src="$options.filters.thumbnail(_.get(item, imageKey), 40)"
        />
      </b-avatar-group>
    </div>

    <!-- Services type's field Limited to multiple section -->
    <div
      v-else-if="hasValue && defaultType === 'services' && Array.isArray(value.services) && value.services.length"
      class="td-long"
    >
      <div
        v-for="(item, index) in value.services"
        :key="`list-users-${index}`"
        :href="item.id"
        class="mr-50 mb-1 d-flex align-items-center"
      >
        <!-- Thumbnail -->
        <a
          :href="image"
          target="_blank"
        >
          <b-avatar
            size="32px"
            :src="$options.filters.thumbnail(item.media[0].url, 40)"
          />
        </a>

        <!-- Service Name -->
        <a
          :href="`/service/${item.id}`"
          target="_blank"
          class="ml-75 line-clamp-1"
        ><span
           v-if="hasValue"
           :title="item.name"
         >{{ item.name }}</span>
          <i
            v-else
            class="text-muted"
          >No name</i></a>
      </div>
    </div>

    <!-- Component type's field -->
    <div
      v-else-if="hasValue && defaultType === 'component'"
    >
      <component
        :is="map.name"
        v-bind="value"
      />
    </div>

    <!-- Badge type's field -->
    <audio
      v-else-if="hasValue && defaultType === 'audio'"
      controls
    >
      <source :src="value">
      Audio player unavailable.
    </audio>

    <!-- Badge type's field -->
    <b-badge
      v-else-if="hasValue && defaultType === 'badge'"
      :variant="map[value]"
      class="text-capitalize"
    >
      {{ value.replace(/_/g, ' ') }}
    </b-badge>

    <!-- Payment Badge type's field -->
    <b-badge
      v-else-if="hasValue && defaultType === 'payment'"
      :variant="value"
    >
      {{ map[value] }}
    </b-badge>

    <!-- Dot type's field -->
    <div
      v-else-if="hasValue && defaultType === 'dot'"
      class="d-flex align-items-center text-nowrap"
    >
      <div :class="`text-${map[value]}`">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8"
            cy="6.5"
            r="5.5"
          />
        </svg>
      </div>

      <span class="text-capitalize ml-25">{{ value.replace(/_/g, ' ') }}</span>
    </div>

    <!-- Color type's field -->
    <div
      v-else-if="hasValue && defaultType === 'color'"
      class="d-flex align-items-center text-nowrap"
    >
      <div
        class="d-flex align-items-center mr-25 border border-gray rounded"
        :style="{ color: value }"
      >
        <svg
          id="Capa_1"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 483.48 483.48"
          xml:space="preserve"
        ><g
          stroke-width="0"
        /><g
          stroke-linecap="round"
          stroke-linejoin="round"
        /><g> <g> <g> <path d="M361.08,0H122.4C54.799,0,0,54.798,0,122.4v238.68c0,67.602,54.799,122.4,122.4,122.4h238.68 c67.602,0,122.4-54.799,122.4-122.4V122.4C483.48,54.798,428.682,0,361.08,0z" /> </g> </g> </g></svg>
      </div>

      <span class="text-uppercase ml-25">{{ value }}</span>
    </div>

    <!-- Profile type's field -->
    <!-- Suitable for general profile -->
    <!-- e.g. User profile, Product profile -->
    <div
      v-else-if="(hasValue || image || defaultUrl) && defaultType === 'profile'"
      class="d-flex align-items-center"
    >
      <!-- Avatar -->
      <a
        :href="image"
        target="_blank"
      >
        <b-avatar
          size="32px"
          :src="defaultProfileImage"
        />
      </a>

      <!-- Name -->
      <a
        v-if="defaultUrl"
        :href="defaultUrl"
        target="_blank"
        class="ml-75 line-clamp-1"
      ><span
         v-if="hasValue"
         :title="value"
       >{{ value }}</span>
        <i
          v-else
          class="text-muted"
        >No name</i></a>
      <i
        v-else
        class="ml-75"
      >{{ value }}</i>
    </div>

    <!-- Datetime type's field -->
    <span
      v-else-if="hasValue && defaultType === 'datetime'"
      class="text-capitalize"
    >{{ value | datetime }}</span>

    <!-- Email format -->
    <a
      v-else-if="hasValue && defaultType === 'email'"
      :href="`mailto:${value}`"
    >{{ value }}</a>

    <!-- Url -->
    <a
      v-else-if="hasValue && defaultType === 'url'"
      :href="value"
      target="_blank"
      class="text-ellipsis"
    >{{ value }}</a>

    <!-- Uploaded ID Image -->
    <a
      v-else-if="hasValue && defaultType === 'UploadedIDImage'"
      :href="value"
      target="_blank"
      class="text-ellipsis"
    >View Image</a>

    <!-- Attachment -->
    <a
      v-else-if="hasValue && defaultType === 'attachment'"
      :href="`https://${value.Host}${value.Path}`"
      target="_blank"
      class="text-ellipsis"
    >View Image</a>

    <!-- Link format -->
    <a
      v-else-if="hasValue && defaultUrl"
      :href="defaultUrl"
      target="_blank"
      class="text-ellipsis"
    >{{ value }}</a>

    <!-- Rating format -->
    <div
      v-else-if="hasValue && defaultType === 'rating'"
      class="d-flex align-items-center gap-1"
    >
      {{ value }}
      <feather-icon
        icon="StarIcon"
        class="text-warning"
        size="16"
      />
    </div>

    <!-- Country field -->
    <CountryField
      v-else-if="hasValue && defaultType === 'country'"
      :code="value"
    />

    <!-- Countries field -->
    <MultipleCountry
      v-else-if="hasValue && defaultType === 'countries'"
      :codes="value"
    />

    <!-- Gold format -->
    <div
      v-else-if="hasValue && defaultType === 'gold'"
      class="d-flex align-items-center"
    >
      <b-img
        :src="goldIcon"
        width="14px"
        class="mr-25"
      />
      <span v-if="value > 0 || hasZero">{{ value | number }}</span>
      <span v-else>FREE</span>
    </div>

    <!-- Meta array -->
    <div
      v-else-if="hasValue && !_.isEmpty(value) && defaultType === 'meta' && Array.isArray(value)"
      class="font-monospace w-100 overflow-hidden"
    >
      <div
        v-for="(item, index) in value"
        :key="`meta-field-${index}`"
        class="border p-25"
      >
        <div
          v-for="(attr, attrIndex) in Object.keys(item).map((key) => ({key, value: item[key]}))"
          :key="`meta-field-${index}-${attrIndex}`"
          class="px-25 text-ellipsis"
        >{{ attr.key }}: {{ attr.value }}</div>
      </div>
    </div>

    <!-- Meta object -->
    <div
      v-else-if="hasValue && !_.isEmpty(value) && defaultType === 'meta'"
      class="font-monospace w-100 overflow-hidden border p-25"
    >
      <div
        v-for="(item, index) in Object.keys(value).map((key) => ({key, value: value[key]}))"
        :key="`meta-field-${index}`"
        class="px-25 text-ellipsis"
      >{{ item.key }}: {{ item.value }}</div>
    </div>

    <!-- ID Boolean -->
    <div v-else-if="hasValue && defaultType === 'verified'">
      <feather-icon
        v-if="value"
        icon="CheckCircleIcon"
        class="text-success"
        size="20"
      />
      <feather-icon
        v-else
        icon="XCircleIcon"
        class="text-warning"
        size="20"
      />
    </div>

    <!-- Number format -->
    <span
      v-else-if="hasValue && typeof value === 'number'"
    >{{ value | number }}</span>

    <!-- Link format -->
    <b-link
      v-else-if="hasValue && typeof value === 'link'"
      :href="value"
      download
    >Download</b-link>

    <!-- Boolean -->
    <div v-else-if="hasValue && typeof value === 'boolean'">
      <feather-icon
        v-if="value"
        icon="CheckCircleIcon"
        class="text-success"
        size="20"
      />
      <feather-icon
        v-else
        icon="XCircleIcon"
        class="text-danger"
        size="20"
      />
    </div>

    <!-- Default -->
    <div
      v-else-if="hasValue && typeof value !== 'object'"
      class="line-clamp-1 d-block"
    >{{ value }}</div>

    <!-- Empty -->
    <div
      v-else
      class="text-muted s-12 py-25"
    >●</div>

    <!-- Suffix -->
    <span v-if="hasValue && defaultSuffix">{{ defaultSuffix }}</span>

    <!-- Copy text -->
    <!-- should have value, array should have item(s) -->
    <!-- or not an array but have value-->
    <div
      v-if="!hideCopy && hasValue && (value.length || typeof value !== 'object')"
      class="position-relative"
    >
      <b-button
        ref="btn-copy"
        variant="flat-dark"
        class="btn-icon btn-copy ml-50"
        size="sm"
        @click.prevent="copyText(value, $event)"
      >
        <feather-icon
          icon="CopyIcon"
          class="pointer-events-none"
          size="16"
        />
      </b-button>
      <!-- Tooltip -->
      <b-tooltip
        ref="tooltip-copy"
        :target="() => $refs['btn-copy']"
        title="Copied!"
        placement="top"
        no-fade
        disabled
      />
    </div>
  </div>
</template>

<script>
import { copy } from '@/helper/clipboard'
import CountryField from '@/layouts/components/CountryField.vue'
import MultipleCountry from '@/layouts/components/MultipleCountry.vue'
import ReviewSummary from '@/layouts/components/ReviewSummary.vue'
import PostSummaryField from '@/layouts/components/PostSummaryField.vue'
import goldIcon from '@/assets/images/icons/gank-gold.svg'

import {
  BBadge,
  BButton,
  BAvatar,
  BImg,
  BAspect,
  BListGroup,
  BListGroupItem,
  BFormRow,
  BCol,
  BTooltip,
  BDropdown,
  BAvatarGroup,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BButton,
    CountryField,
    ReviewSummary,
    PostSummaryField,
    MultipleCountry,
    BAvatar,
    BImg,
    BAspect,
    BListGroup,
    BListGroupItem,
    BFormRow,
    BCol,
    BTooltip,
    BDropdown,
    BAvatarGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: '',
    },
    prefix: {
      type: [String, Object],
      default: '',
    },
    suffix: {
      type: [String, Object],
      default: '',
    },
    type: {
      type: [String, Object],
      default: '',
    },
    map: {
      type: Object,
      default: () => {},
    },
    image: {
      type: String,
      default: '',
    },
    imageKey: {
      type: String,
      default: '',
    },
    titleKey: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    url: {
      type: [String, Object],
      default: '',
    },
    hasDetail: {
      type: Boolean,
      default: false,
    },
    // don't replace 0 to FREE
    hasZero: {
      type: Boolean,
      default: false,
    },
    hideCopy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      goldIcon,
    }
  },
  computed: {
    defaultImage() {
      return this.$options.filters.thumbnail(this.value, 40)
    },
    defaultProfileImage() {
      return this.$options.filters.thumbnail(this.image, 40)
    },
    // get only first item if there are more than 2 items
    defaultBadges() {
      if (!this.value) {
        return undefined
      }

      if (this.value.length > 2) {
        return this.value.slice(0, 1)
      }

      return this.value
    },
    // get dynamic prefix
    defaultPrefix() {
      if (!this.prefix) {
        return undefined
      }

      if (typeof this.prefix === 'string') {
        return this.prefix
      }

      if (typeof this.prefix !== 'object') {
        return undefined
      }

      // dynamic value
      return this.prefix.callback(this.data)
    },
    // get dynamic suffix
    defaultSuffix() {
      if (!this.suffix) {
        return undefined
      }

      if (typeof this.suffix === 'string') {
        return this.suffix
      }

      if (typeof this.suffix !== 'object') {
        return undefined
      }

      // dynamic value
      return this.suffix.callback(this.data)
    },
    // get dynamic type
    defaultType() {
      if (!this.type) {
        return undefined
      }

      if (typeof this.type === 'string') {
        return this.type
      }

      if (typeof this.type !== 'object') {
        return undefined
      }

      // dynamic value
      return this.type.callback(this.data)
    },
    defaultUrl() {
      if (this.defaultType === 'url' && !this.value && (this.value.includes('http') || this.value.includes('www') || this.value.includes('.com'))) {
        return this.value
      }

      if (!this.url) {
        return undefined
      }

      if (typeof this.url === 'string') {
        return this.url
      }

      if (typeof this.url !== 'object') {
        return undefined
      }

      // dynamic value
      return this.url.callback(this.data)
    },
    // get only first item if there are more than 2 items
    hiddenBadges() {
      if (!this.value) {
        return undefined
      }

      if (this.value.length > 2) {
        return this._.differenceWith(this.value, this.defaultBadges, this._.isEqual)
      }

      // the default is no hidden badges
      return []
    },
    detailRoute() {
      if (this.url) {
        return this.defaultUrl
      }

      // if already in detail route
      if (this.$route.path.includes(this.value)) {
        return this.$route.path
      }

      if (this.hasDetail) {
        // return detail route
        return `${this.$route.path}/${this.value}`
      }

      // open popup instead
      return `${this.$route.path}#${this.value}`
    },
    // using if(value) and !_.isEmpty for number 0 will return false
    hasValue() {
      return this.value !== undefined && this.value !== null && this.value !== ''
    },
  },
  methods: {
    copyText(val) {
      const text = typeof val === 'object' && Array.isArray(val)
        ? JSON.stringify(val)
        : val.toString()

      copy(text)
        .then(() => {
          // show tooltip only when success
          this.$refs['tooltip-copy'].$emit('open')

          // hide tooltip after half a second
          setTimeout(() => {
            this.$refs['tooltip-copy'].$emit('close')
          }, 500)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.b-table tbody {
  td {
    .btn-copy {
      z-index: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(0);
      opacity: 0;
      transition: opacity 0.1s ease-in-out, transform 0s linear;
      transition-delay: 0s;
    }

    &:hover {
      .btn-copy {
        transform: translateY(-50%) scale(1);
        opacity: 1; // do not use 100%, in production will changed to 1%
        transition-delay: 0.5s;
      }
    }

    .c-avatar-group {
      .b-avatar-group-inner {
        .b-avatar:only-child {
          margin: 0 !important;
        }
      }
    }
  }
}
</style>
