<template>
  <div>
    <div
      v-if="codes.length"
      class="d-flex gap-2"
    >
      <CountryField
        v-for="(item, index) in codes"
        :key="`list-countries-${index}`"
        :code="item"
        :icon-only="codes.length > 1"
      />
    </div>
    <div
      v-if="!codes.length"
      class="text-muted s-12 py-25"
    >
      ●
    </div>
  </div>
</template>

<script>
import CountryField from '@/layouts/components/CountryField.vue'

export default {
  components: {
    CountryField,
  },
  props: {
    codes: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
