<template>
  <div class="d-flex align-items-center text-nowrap">
    <div v-if="country">
      <b-img
        v-b-tooltip.v-dark.top="{ title: country.name, disabled: !iconOnly }"
        width="22px"
        :src="country.flagIcon"
      />
      <span
        v-if="!iconOnly"
        class="ml-50"
      >{{ country.name }}</span>
    </div>
    <div v-else>
      {{ code }}
    </div>
  </div>
</template>

<script>
import idFlag from '@/assets/images/flags/id.svg'
import myFlag from '@/assets/images/flags/my.svg'
import sgFlag from '@/assets/images/flags/sg.svg'
import frFlag from '@/assets/images/flags/fr.svg'
import twFlag from '@/assets/images/flags/tw.svg'
import trFlag from '@/assets/images/flags/tr.svg'
import usFlag from '@/assets/images/flags/us.svg'
import phFlag from '@/assets/images/flags/ph.svg'
import arFlag from '@/assets/images/flags/ar.svg'
import thFlag from '@/assets/images/flags/th.svg'

import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countries: [
        {
          code: 'id',
          name: 'Indonesia',
          flagIcon: idFlag,
        },
        {
          code: 'my',
          name: 'Malaysia',
          flagIcon: myFlag,
        },
        {
          code: 'sg',
          name: 'Singapore',
          flagIcon: sgFlag,
        },
        {
          code: 'fr',
          name: 'France',
          flagIcon: frFlag,
        },
        {
          code: 'tw',
          name: 'Taiwan',
          flagIcon: twFlag,
        },
        {
          code: 'tr',
          name: 'Turkey',
          flagIcon: trFlag,
        },
        {
          code: 'us',
          name: 'United States',
          flagIcon: usFlag,
        },
        {
          code: 'ph',
          name: 'Philippines',
          flagIcon: phFlag,
        },
        {
          code: 'ar',
          name: 'Argentina',
          flagIcon: arFlag,
        },
        {
          code: 'th',
          name: 'Thailand',
          flagIcon: thFlag,
        },
      ],
    }
  },
  computed: {
    country() {
      return this._.find(this.countries, item => item.code === this.code.toLowerCase())
    },
  },
}
</script>

<style scoped>

</style>
